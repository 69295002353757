<template>
  <div class="word">
    <h2>Сводная таблица по кредитам из ГОСО</h2>
    <div id="divTable" class="divTable">
      <div id="divCaption" class="divCaption">TABLE SUMMARY TEXT</div><!-- Div Header  -->
      <div class="divHeaderRow">
        <div id="divHeaderCell1" class="divHeaderCell">№</div>
        <div id="divHeaderCell2" class="divHeaderCell">Наименование циклов и дисциплин</div>
        <div id="divHeaderCell3" class="divHeaderCell">Тудоемкость в часах</div>
        <div id="divHeaderCell4" class="divHeaderCell">Трудоемкость в кредитах</div>
        <div id="divHeaderCell5" class="divHeaderCell">Примечание</div>
      </div><!-- HeaderRow  Ends -->
      <div>
        <div v-for="(item, index) in gosoTemplate_form.studyLevels"
             :key="index">
          <!--Start Footer  Row  -->
          <div class="divFooterRow divRow">
            <!-- Start Footer  cell  -->
            <div class="divFooterCell">{{ item.name }}<!-- End Footer  Cell  -->
            </div>
            <!-- End Footer  Row  -->
          </div>
          <!-- Row 1 -->
          <div
              v-for="(item, index) in gosoTemplate_form.gosoTemplateInfos.filter(i => (i.parent_id ==0 && i.study_level_id==item.id))"
              :key="index">
            <div class="divRow">
              <div class="divCell">{{ index + 1 }}</div>
              <div class="divCell">{{ getOneGosoCycleName(item.goso_cycle_name_id)[0]}}</div>
              <div class="divCell">{{ item.credit }}</div>
              <div class="divCell">{{ item.credit }}</div>
              <div class="divCell">{{ item.note }}</div>
            </div><!-- Row  Ends1 -->

            <div
                v-for="(childItem, childIndex) in gosoTemplate_form.gosoTemplateInfos.filter(i => i.parent_id == item.id)"
                :key="childIndex">
              <div class="divRow">
                <div class="divCell">{{index+1}}.{{ childIndex + 1 }}</div>
                <div class="divCell">{{ getOneGosoComponentName(childItem.goso_cycle_name_id)[0]}}</div>
                <div class="divCell">{{ childItem.credit }}</div>
                <div class="divCell">{{ childItem.credit }}</div>
                <div class="divCell">{{ childItem.note }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Ends Main div  -->
  </div>
</template>

<script>
// import Paginate from '../common/Paginate.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "GosoTemplatesView",
  components: {
    // Paginate
  },

  methods: {
    ...mapActions('gosoTemplates', ['GET_STUDY_LEVEL', 'GET_GOSO_CYCLE_NAME', 'GET_GOSO_TEMPLATE_DATA_ALL']),

    getOneGosoCycleName(id) {
      return this.gosoTemplate_form.cycleNames.filter(i => (i.id == id && i.id != 0))
    },
    getOneGosoComponentName(id) {
      return this.gosoTemplate_form.componentNames.filter(i => (i.id == id && i.id != 0))
    }
  },

  computed: {
    ...mapState('gosoTemplates', ['gosoTemplate_form']),
  },

  async mounted() {
    await this.GET_STUDY_LEVEL();
    await this.GET_GOSO_CYCLE_NAME();
    await this.GET_GOSO_TEMPLATE_DATA_ALL();

  }
}
</script>
<style scoped>
.word {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.divTable {
  width: 100%;
  display: block;
  height: 100% !important;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  line-Height: 26px;
}

.divCaption {
  width: 100%;
  background-color: transparent;
  position: relative;
  font-size: 18px;
  color: #2293FF;
  text-align: center;
  line-height: 20px;
  font-family: 'arial', helvetica, sans-serif;
  font-weight: bold;
  border: 0px solid #f2f2f2;
}

.divHeaderRow {
  width: 100%;
  position: relative;
}

.divHeaderCell {
  width: 19.5%;
  position: relative;
  font-family: 'arial', helvetica, sans-serif;
  font-size: 13px;
  font-style: normal;
  float: left;
}


.divRow {
  width: 100%;
  display: table;
  position: relative;
  color: #000000;
}

.divCell {
  border: 1px solid #F60;
  width: 19.5%;
  display: block;
  position: relative;
  background-color: #FFF;
  color: #000000;
  font-size: 12px;
  float: left;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0px;
  word-spacing: 0px;
}


.divFooterRow {
  position: relative;
  width: 100%;
}

.divFooterCell {
  width: 100%;
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  spacing: 0px;
  text-align: center;
  position: relative;
  line-height: 20px;
  font-weight: bold;
}
</style>